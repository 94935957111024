import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import KpiWrapper from '../../../components/kpiWrapper';
import { useTranslation } from 'react-i18next';
import { getReservationTable } from '../../../helpers/kpiHelper';

export default function ReservationTable({ isLoading }) {
    const { t } = useTranslation()
    const reservations = useSelector(state => state.kpiReducer.reservations)
    const isReservationsLoading = useSelector(state => state.kpiReducer.reservationsLoading)
    const { rows, totalBO, totalUserApp, totalUserWeb } = getReservationTable({ reservations })


    isLoading = isLoading || isReservationsLoading
    const displayNoData = isNaN(totalBO) || isNaN(totalUserApp) || isNaN(totalUserWeb)

    return (

        <KpiWrapper title={t("commandBox.summary.reservationTable.title")} displayNoData={displayNoData} isLoading={isLoading} skeletonVariant="rect">
            <TableContainer component={Box}>
                <Table arial-label="reservation table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t("commandBox.summary.reservationTable.header.cell1")}</TableCell>
                            <TableCell align="right">{t("commandBox.summary.reservationTable.header.cell2")}</TableCell>
                            <TableCell align="right">{t("commandBox.summary.reservationTable.header.cell3")}</TableCell>
                            <TableCell align="right">{t("commandBox.summary.reservationTable.header.cell4")}</TableCell>
                            <TableCell align="right">{t("commandBox.summary.reservationTable.header.cell5")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow key={row.name}>
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell align="right">
                                    <Typography style={{ fontSize: "16px" }}>
                                        {row.byBackOffice}
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography style={{ fontSize: "16px" }}>
                                        {row.byUserApp}
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography style={{ fontSize: "16px" }}>
                                        {row.byUserWeb}
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography style={{ fontSize: "16px", fontWeight: "600" }}>
                                        {row.total}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell component="th" scope="row">
                                <Typography style={{ fontSize: "16px" }}>
                                    {t("commandBox.summary.reservationTable.row4")}
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography style={{ fontSize: "16px", fontWeight: "600" }}>
                                    {totalBO}
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography style={{ fontSize: "16px", fontWeight: "600" }}>
                                    {totalUserApp}
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography style={{ fontSize: "16px", fontWeight: "600" }}>
                                    {totalUserWeb}
                                </Typography>
                            </TableCell>
                            <TableCell align="right">

                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </KpiWrapper>
    )
}