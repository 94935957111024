import { Grid, Paper } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import TotalJourneysChart from "./components/totalJourneysChart";
import NumberPassengersChart from "./components/numberPassengersChart";
import JourneysDistanceChart from "./components/journeysDistanceChart";
import JourneysDurationChart from "./components/journeysDurationChart";
import { useKPIStyles } from "../../styles";

export default function JourneysKPI() {
    const classes = useKPIStyles()
    const servicesLoading = useSelector(state => state.serviceReducer.pending)

    return (
        <section className={classes.wrapper}>
            <Grid container spacing={1}>
                <Grid item sm={12}>
                    <Paper className={[classes.paper, classes.paddingGraph]} >
                        <TotalJourneysChart isLoading={servicesLoading} />
                    </Paper>
                </Grid>
                <Grid item sm={12}>
                    <Paper className={[classes.paper, classes.paddingGraph]} >
                        <NumberPassengersChart isLoading={servicesLoading} />
                    </Paper>
                </Grid>
                <Grid item sm={12}>
                    <Paper className={[classes.paper, classes.paddingGraph]} >
                        <JourneysDistanceChart isLoading={servicesLoading} />
                    </Paper>
                </Grid>
                <Grid item sm={12}>
                    <Paper className={[classes.paper, classes.paddingGraph]} >
                        <JourneysDurationChart isLoading={servicesLoading} />
                    </Paper>
                </Grid>
            </Grid>
        </section >
    )
}