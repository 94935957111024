import React from 'react'
import useDownloadCSV from '../hooks/useDownloadCSV'
import PropTypes from 'prop-types'
import CsvSVG from '../../../resources/svgs/csv'
import IconButton from './iconButton'
import useKpiLoading from '../hooks/useKpiLoading'
import i18next from 'i18next'
import { getXLSXBlob } from '../../../helpers/xlsxExport'

function DownloadPageCSV({ page }) {
    const { isLoading } = useKpiLoading()
    const { csvList } = useDownloadCSV({ page })

    const downloadCSV = () => {
        const blob = getXLSXBlob(csvList)
        const url = URL.createObjectURL(blob)

        const link = document.createElement('a')
        link.href = url
        const title = i18next.t(`commandBox.${page}.title`)
        link.download = `${title}_kpi.xlsx`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        URL.revokeObjectURL(url)
    }

    return (
        <IconButton text="Descargar Csv" disabled={isLoading} onClickAction={downloadCSV}>
            <CsvSVG />
        </IconButton>
    )
}

export default React.memo(DownloadPageCSV)

DownloadPageCSV.propTypes = {
    page: PropTypes.string.isRequired,
}
